import AppTitle from '@/components/AppTitle';
import { useState } from 'react';
import AuditPageHeader from '../Audit/AuditPageHeader';
import { AdminPanelSettingsOutlined as AdminSecurityIcon } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import EventTypeButton from '../Audit/EventTypeButton';
import WideAppContent from '@/components/WideAppContent';
import AdminAuditEventsWithSearchForm from './AdminAuditEventsWithSearchForm';
import AdminSecurityAuditEventsWithSearchForm from './AdminSecurityAuditEventsWithSearchForm';

const AdminAudit = () => {
  const [isDisplayingSecurityAuditEvents, setIsDisplayingSecurityAuditEvents] =
    useState(false);

  return (
    <>
      <AppTitle>Admin audit & security</AppTitle>
      <AuditPageHeader>
        <h1>
          <AdminSecurityIcon />
          Audit admin{' '}
          {isDisplayingSecurityAuditEvents
            ? 'security events'
            : 'user activity'}
        </h1>
        <ButtonGroup variant="outlined" size="small" color="secondary">
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(false)}
            selected={!isDisplayingSecurityAuditEvents}
            disabled={!isDisplayingSecurityAuditEvents}
          >
            User activity
          </EventTypeButton>
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(true)}
            selected={isDisplayingSecurityAuditEvents}
            disabled={isDisplayingSecurityAuditEvents}
          >
            Security events
          </EventTypeButton>
        </ButtonGroup>
      </AuditPageHeader>
      <WideAppContent>
        {isDisplayingSecurityAuditEvents ? (
          <AdminSecurityAuditEventsWithSearchForm />
        ) : (
          <AdminAuditEventsWithSearchForm />
        )}
      </WideAppContent>
    </>
  );
};

export default AdminAudit;
