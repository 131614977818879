import { useState } from 'react';
import { Security as SecurityIcon } from '@mui/icons-material';
import AuditEventsWithSearchForm from './AuditEventsWithSearchForm';
import SecurityAuditEventsWithSearchForm from './SecurityAuditEventsWithSearchForm';
import { ButtonGroup } from '@mui/material';
import AuditPageHeader from './AuditPageHeader';
import EventTypeButton from './EventTypeButton';
import AppTitle from '@/components/AppTitle';
import WideAppContent from '@/components/WideAppContent';
import { AutomatedSchoolsContextProvider } from './SchoolsContext';

const Audit = () => {
  const [isDisplayingSecurityAuditEvents, setIsDisplayingSecurityAuditEvents] =
    useState(false);

  return (
    <AutomatedSchoolsContextProvider>
      <AppTitle>Audit & security</AppTitle>
      <AuditPageHeader>
        <h1>
          <SecurityIcon />
          Audit{' '}
          {isDisplayingSecurityAuditEvents
            ? 'security events'
            : 'user activity'}{' '}
          on Egodact
        </h1>
        <ButtonGroup variant="outlined" size="small" color="secondary">
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(false)}
            selected={!isDisplayingSecurityAuditEvents}
            disabled={!isDisplayingSecurityAuditEvents}
          >
            User activity
          </EventTypeButton>
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(true)}
            selected={isDisplayingSecurityAuditEvents}
            disabled={isDisplayingSecurityAuditEvents}
          >
            Security events
          </EventTypeButton>
        </ButtonGroup>
      </AuditPageHeader>
      <WideAppContent>
        {isDisplayingSecurityAuditEvents ? (
          <SecurityAuditEventsWithSearchForm />
        ) : (
          <AuditEventsWithSearchForm />
        )}
      </WideAppContent>
    </AutomatedSchoolsContextProvider>
  );
};

export default Audit;
