import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const AuditPageHeader = styled('header')({
  marginTop: 32,
  h1: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    '.MuiSvgIcon-root': {
      marginRight: 12,
    },
  },
  [mediaQuery(1600)]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    h1: {
      marginBottom: 18,
      fontSize: 24,
    },
  },
  [mediaQuery(672)]: {
    alignItems: 'flex-start',
  },
  [mediaQuery(540)]: {
    'h1 .MuiSvgIcon-root': {
      display: 'none',
    },
  },
});

export default AuditPageHeader;
